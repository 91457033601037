<template lang="pug">
Container
	h1 {{ header }}

Container
	SliderPractice(class='SliderPractice', :practice='practiceState', :navigation='true')

Container
	UnitConsultation(type='employees')

Container
	UnitReviews

UiSubscribe
</template>

<script lang="ts" setup>
defineComponent({
	name: 'ArbitragePage',
});

/**
 * Define hooks
 */
const { practiceState, fetchPractice } = usePractice();
const { header } = useMeta();

/**
 * Define fetch
 */
await fetchPractice();
</script>
